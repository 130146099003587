import React from "react";
import Image from "@components/Image";
import classNames from "classnames";

import "./section.styles.scss";

const Section = ({ title, text, image, isGray }) => {

  return (
    <section className={classNames(
      "section",
      {
        "section--gray": isGray,
      },
    )}>
      <div className="section__content-wrapper">
        <div className="section__img">
          <Image data={image} />
        </div>
        <h2 className="section__title">{title}</h2>
        <p className="section__text">{text}</p>
      </div>
    </section>
  );
};

export default Section;
