import React, { useRef, useEffect, useState } from "react";
import Header from "@layouts/Header";
import ContactForm from "@containers/ContactForm";
import Section from "./components/Section/Section";
import Button from "@components/Button";

import "./agencies.styles.scss";

const AgenciesTemplate = ({ data }) => {
  const header = data?.header || {};
  const sections = data?.sections || {};

  const [scrollTop, setScrollTop] = useState(0);

  const contactEl = useRef(null)
  const contactBtnEl = useRef(null)
  const sectionsEl = useRef(null)
  const headerEl = useRef(null)

  const executeScroll = () => contactEl.current.scrollIntoView({ behavior: "smooth", block: "center" })

  useEffect(() => {
    checkOffset();
  }, []);
  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      checkOffset();
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const checkOffset = () => {
    if (window.innerWidth <= 900) {
      if (contactEl.current.getBoundingClientRect().bottom <= 100)
        contactBtnEl.current.style.opacity = 1;
      else
        contactBtnEl.current.style.opacity = 0;
    }

    if (window.innerWidth > 900) {
      //* AT HEADER
      if (headerEl.current.getBoundingClientRect().bottom >= 0) {
        contactEl.current.style.position = 'absolute';
        contactEl.current.style.top = headerEl.current.offsetHeight + 100 + "px";
        contactEl.current.style.bottom = "unset";
      }
      //* AT CENTER
      if (((contactEl.current.getBoundingClientRect().bottom + 99) < (sectionsEl.current.getBoundingClientRect().bottom)) && (headerEl.current.getBoundingClientRect().bottom - 100 < 0)) {
        contactEl.current.style.position = 'fixed';
        contactEl.current.style.top = "200px";
        contactEl.current.style.bottom = "unset";
      }
    }
    //* AT FOOTER
    if ((contactEl.current.getBoundingClientRect().bottom + 100) >= (sectionsEl.current.getBoundingClientRect().bottom)) {
      contactEl.current.style.position = 'absolute';
      contactEl.current.style.bottom = "100px";
      contactEl.current.style.top = "unset";
    }
  }

  return (
    <div className="agencies">
      <Header ref={headerEl} {...header} isCentered />
      <div className="agencies__contact-form" ref={contactEl}>
        <ContactForm />
      </div>
      <div ref={contactBtnEl} className="agencies__contact-form__button-wrapper">
        <Button className="agencies__contact-form__button" onClick={executeScroll} >Let's connect</Button>
      </div>
      <div ref={sectionsEl} className="agencies__sections-wrapper">
        {sections.map((section, i) => (
          <Section key={i} {...section} isGray={i % 2} />
        ))}
      </div>
    </div>
  );
};

export default AgenciesTemplate;
